@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Inter, sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0d1b2a;
}

pre,
code {
  font-family: Inter, sans-serif;
}

@layer components {
  /* Formatting */
  .container {
    @apply p-4 md:p-6;
  }

  .section {
    @apply md:p-4 px-4;
  }

  /* Nav items */
  .nav-link {
    @apply w-full h-10 z-[1] transition-colors ease-in-out-quart duration-200 hover:text-[#ffffff];
  }

  .nav-link-content {
    @apply grid-cols-2;
  }

  .nav-link-divider {
    @apply ml-11 mr-4 border-b-[.5px] border-paragraph_dark border-opacity-30;
  }

  .nav-line-top {
    @apply w-6 border-b-[.5px] border-paragraph_dark border-opacity-80;
  }

  .nav-line-bottom {
    @apply w-6 border-b-[.5px] border-paragraph_dark border-opacity-80;
  }

  .social-icon {
    @apply w-12 h-12 p-2 text-[#fff] [&>svg]:hover:hidden [&>svg]:hover:opacity-0 transition-all ease-in-out-quart duration-500;
  }

  .social-icon-full {
    @apply w-12 h-12 p-2 transition-all ease-in-out-quart duration-500;
  }

  .divider {
    @apply border-b-[.5px] border-paragraph_dark border-opacity-10;
  }

  /* Typography */
  .h2 {
    @apply text-2xl mb-2 font-bold text-paragraph_dark;
  }

  .h3 {
    @apply text-base mb-4 font-bold  text-paragraph_dark;
  }

  .link {
    @apply text-paragraph_dark hover:text-subheading_dark font-bold;
  }

  /* Animations */
  .quart-in-out {
    @apply transition-all ease-in-out-quart duration-300;
  }

  .border-slide-in {
    @apply bg-[#1cbfaa0f] drop-shadow-lg border-opacity-30 !border-t-subheading_dark_low !opacity-100 [&>a>svg]:block;
  }

  .hover-border {
    @apply hover:bg-[#1cbfaa0f] hover:drop-shadow-lg hover:border-t-subheading_dark_low  hover:border-opacity-30;
  }

  .link-hover {
    @apply border-b border-b-[#fff0] duration-300 transition-all hover:border-b-subheading_dark;
  }

  /* Specifics */
  .technology-tag {
    @apply bg-subheading_dark_low
          text-subheading_dark_high
            inline-flex
            text-xs
            px-4
            py-1
            mr-2
            mb-2
            font-bold
            rounded-full
            shadow-lg;
  }

  .tile {
    @apply text-paragraph_dark_low relative p-4 border-[0.5px] border-[#fff0] rounded-md hover:!opacity-100 [&>a>svg]:hover:block;
  }

  .mobile-sticky-heading {
    @apply mb-4 max-md:sticky max-md:top-0 max-md:-ml-8 max-md:pl-4 max-md:py-3 max-md:backdrop-blur max-md:w-screen max-md:z-10 max-md:items-center max-md:flex max-md:bg-primary_dark_low;
  }

  .table {
    @apply border-collapse w-full text-left [&>*>*]:border-b [&>*>*]:border-b-[rgba(203,213,225,.1)];
  }
}
