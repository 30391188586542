#social-icons > a:hover .social-icon-full {
  display: block;
  opacity: 100;

  filter: invert(99%) sepia(0%) saturate(7393%) hue-rotate(191deg)
    brightness(136%) contrast(100%);
}

#social-icons > a:hover .social-icon {
  display: hidden;
  opacity: 0;
}

#masters-link:hover {
  cursor: url("/src/assets/masters-logo.svg") 32 32, auto !important;
}

#dustbringer:hover {
  cursor: url("https://uploads.coppermind.net/Dustbringers_glyph.svg") 32 32,
    auto !important;
}
